import * as React from 'react';
import { useState } from 'react';
import { renderComponent } from '@General/renderComponent';
import { ExportCsv } from '@Page/ExportMenu/component/ExportCSV';

// TRIP-51056
export function ExportCsvEmptyShell() {
    const [open, setOpen] = useState(true);

    const downloadButton = $("[data-testId='download_CSV_button']").get(0);

    if (downloadButton) {
        downloadButton.addEventListener('mousedown', (e) => {
            setOpen(true);
        });
    }
    return <div>{open && <ExportCsv onClose={() => setOpen(false)} />}</div>;
}

export function renderExportCsv(domId: string) {
    renderComponent(ExportCsvEmptyShell, domId);
}
