import product from '@Page/LogisticsV2/pages/Product/overview/Banner/product.svg';
import { BannerImage, Button } from '@tlx/atlas';
import * as React from 'react';
import { Tour } from '@tlx/tourme-core';
import { runTour } from '../../../../../../../../js/modules/tourme';
import newProductOverviewAtlasLogistics from '@Page/LogisticsV2/pages/Product/overview/TourMe/GOLDSHARK_AtlasProductOverviewLogistics_TOUR.json';
import newProductOverviewAtlas from '@Page/LogisticsV2/pages/Product/overview/TourMe/GOLDSHARK_AtlasProductOverview_TOUR.json';
import {
    BannerButtons,
    BannerDescription,
    BannerPersistence,
    BannerTitle,
    GetStartedLabel,
    MinimizableBanner,
    useGetStartedBanner,
} from '@tlx/beehive-shared';

export const GetStartedBannerProductOverview = () => {
    const bannerInfo = useGetStartedBanner(
        'ProductOverviewBanner',
        BannerPersistence.Dismissible
    );

    if (!bannerInfo) {
        return null;
    }

    return (
        <MinimizableBanner use={bannerInfo} className="atl-max-w-full">
            <BannerImage src={product} alt="product" className="atl-mr-24" />
            <GetStartedLabel />
            <BannerTitle>
                {getMessage('get_started_banner_product_overview_title')}
            </BannerTitle>
            <BannerDescription>
                {getMessage('get_started_banner_product_overview_description')}
            </BannerDescription>
            <BannerButtons>
                <Button
                    onClick={() =>
                        moduleLogistics
                            ? runTour(newProductOverviewAtlasLogistics as Tour)
                            : runTour(newProductOverviewAtlas as Tour)
                    }
                    data-testid="product-overview-banner-tutorial-button"
                    aria-label="product-overview-banner-tutorial-button"
                >
                    {getMessage('automation_starting_guide')}
                </Button>
            </BannerButtons>
        </MinimizableBanner>
    );
};
