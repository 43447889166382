import React, { useState } from 'react';
import {
    formatTimeStamp,
    getStocktakingValue,
    LOCATION_NOT_CHOSEN,
    ProductLineDTO,
} from '@Page/LogisticsV2/pages/Stocktaking/utils';
import {
    ApprovedIcon,
    Button,
    ChatIcon,
    Dropdown,
    DropdownDrawer,
    DropdownOpener,
    DropdownScrollContainer,
    DropdownSearch,
    Icon,
    InlineMoneyField,
    Option,
} from '@tlx/atlas';
import { AsyncDropdownOptions } from '@tlx/astro-shared';
import { ApiUrl, AutoSaveStatusType } from '@Page/LogisticsV2/utils/enums';
import { NumberFormat } from '@Page/Salary5/Components/NumberFormat/NumberFormat';
import {
    doChangeLocation,
    doCountLine,
    doUpdateLine,
} from '@Page/LogisticsV2/pages/Stocktaking/details2/StocktakingDetailsActions';
import { getValidationMessagesFromResponse } from '@Page/LogisticsV2/utils/functions';
import { useStocktakingDetails } from '@Page/LogisticsV2/pages/Stocktaking/details2/useStocktakingDetails';

export function StocktakingDetailsMobileBody({
    line,
    editLocation,
    columns,
    onChange,
}: {
    line: ProductLineDTO;
    editLocation: boolean;
    columns: string[];
    onChange: (
        status: AutoSaveStatusType,
        lineId?: number,
        shouldAnimate?: boolean
    ) => void;
}) {
    const [isExpanded, setIsExpanded] = useState(false);
    const stocktaking = useStocktakingDetails();
    const showButton =
        ((columns.includes('comment') && line.comment !== '') ||
            columns.includes('unit') ||
            columns.includes('deviation') ||
            columns.includes('costPrice') ||
            columns.includes('value')) &&
        !stocktaking.data?.isCompleted;

    const handleChangeLocation = async (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        onChange(AutoSaveStatusType.PENDING);
        const { error } = await doChangeLocation(line.id, event.target.value);
        if (error === undefined) {
            onChange(AutoSaveStatusType.SAVED);
            if (event.target.value !== LOCATION_NOT_CHOSEN.id) {
                // setEditLocation(false);
            }
        } else {
            onChange(AutoSaveStatusType.ERROR);
        }
    };

    const handleCountLine = async () => {
        onChange(AutoSaveStatusType.PENDING);
        const { error } = await doCountLine(line.id);
        if (error === undefined) {
            onChange(AutoSaveStatusType.SAVED, line.id, true);
        } else {
            onChange(AutoSaveStatusType.ERROR);
            getValidationMessagesFromResponse(
                error,
                getMessage('validation_cannot_count_line')
            );
        }
    };

    const handleChangeLine = async (value: string) => {
        onChange(AutoSaveStatusType.PENDING);
        const { error } = await doUpdateLine(line.id, value, undefined);
        if (error === undefined) {
            onChange(AutoSaveStatusType.SAVED);
        } else {
            onChange(AutoSaveStatusType.ERROR);
            getValidationMessagesFromResponse(
                error,
                getMessage('validation_cannot_save_count')
            );
        }
    };

    return (
        <tbody>
            <tr className={`atl-tr atl-border-white row-${line.id}`}>
                {line.counted ? (
                    <>
                        <MobileCountedCell count={line.count} />
                        <MobileConfirmedCell
                            name={`${line.counter?.firstName ?? ''} ${
                                line.counter?.lastName ?? ''
                            }`}
                            dateCounted={line.dateCounted}
                        />
                    </>
                ) : (
                    <MobileNotCountedCell
                        count={line.count}
                        onChange={(value) => handleChangeLine(value)}
                        onCountLine={handleCountLine}
                    />
                )}
            </tr>
            {(isExpanded || stocktaking.data?.isCompleted) && (
                <tr className="atl-tr atl-border-white">
                    {columns.map((column) => (
                        <StocktakingDetailsMobileTableRowCell
                            key={column}
                            column={column}
                            line={line}
                        />
                    ))}
                </tr>
            )}
            {line.comment !== '' &&
                (isExpanded || stocktaking.data?.isCompleted) &&
                columns.includes('comment') && (
                    <tr className="atl-tr atl-border-white">
                        <MobileCommentRow comment={line.comment} />
                    </tr>
                )}
            <tr className="atl-tr atl-border-white">
                <td className="atl-td" colSpan={4}>
                    <div
                        className="atl-grid atl-gap-16 goldshark-override"
                        style={{
                            gridTemplateColumns: '1fr 1fr',
                        }}
                    >
                        {((!line.location && !line.counted) || editLocation) &&
                            columns.includes('location') && (
                                <MobileEditableLocationCell
                                    warehouseId={0}
                                    onChange={handleChangeLocation}
                                />
                            )}
                        {line.location &&
                            !editLocation &&
                            columns.includes('location') && (
                                <MobileLocationRow
                                    locationName={line?.location?.displayName}
                                />
                            )}
                        {showButton && (
                            <Button
                                className="atl-justify-end atl-mr-2"
                                style={{
                                    gridColumnEnd: '-1',
                                }}
                                data-testid={
                                    isExpanded
                                        ? 'stocktaking-show-less-button'
                                        : 'stocktaking-show-more-button'
                                }
                                variant="tertiary"
                                onClick={() => setIsExpanded(!isExpanded)}
                            >
                                {isExpanded
                                    ? getMessage('text_show_less2')
                                    : getMessage('text_show_more2')}
                            </Button>
                        )}
                    </div>
                </td>
            </tr>
        </tbody>
    );
}

function MobileCountedCell({ count }: { count: number }) {
    return (
        <td className="atl-td atl-align-middle atl-items-center " colSpan={1}>
            <div className="atl-flex atl-flex-col">
                <div>{getMessage('text_count')}</div>
                <div className="atl-text-grey-40">
                    <NumberFormat
                        value={count}
                        options={{
                            style: 'decimal',
                            maximumFractionDigits: 2,
                        }}
                    />
                </div>
            </div>
        </td>
    );
}

function StocktakingDetailsMobileTableRowCell({
    column,
    line,
}: {
    column: string;
    line: ProductLineDTO;
}): JSX.Element {
    const englishLanguage = window.locale.includes('en');
    switch (column) {
        case 'unit':
            return (
                <MobileUnitCell
                    unit={
                        line?.product?.productUnit
                            ? englishLanguage
                                ? line.product?.productUnit?.nameShortEN ||
                                  line.product?.productUnit?.nameEN
                                : line.product?.productUnit?.nameShort
                            : '-'
                    }
                />
            );
        case 'deviation':
            return (
                <MobileDeviationCell
                    deviation={line.count - line.expectedStock}
                />
            );
        case 'costPrice':
            return (
                <MobileCostPriceCell
                    costPrice={
                        line.counted
                            ? line.unitCostCurrency
                            : line.product.costExcludingVatCurrency
                    }
                />
            );
        case 'value':
            return <MobileValueCell value={getStocktakingValue(line)} />;
        default:
            return <></>;
    }
}

function MobileConfirmedCell({
    name,
    dateCounted,
}: {
    name: string;
    dateCounted?: string;
}) {
    return (
        <td
            className="atl-td atl-whitespace-nowrap atl-align-middle"
            colSpan={3}
        >
            <div className="atl-flex atl-flex-col">
                <div> {getMessage('text_confirmed_noarg')}</div>
                <div className={'atl-flex atl-gap-8 atl-items-center'}>
                    <div>
                        <ApprovedIcon
                            className="atl-p-2 atl-rounded-full atl-bg-turquoise-20"
                            size="small"
                        />
                    </div>
                    <span className="atl-text-grey-40">{name}</span>
                    <span className="atl-text-grey-40">
                        {formatTimeStamp(dateCounted ?? '')}
                    </span>
                </div>
            </div>
        </td>
    );
}

function MobileNotCountedCell({
    count,
    onChange,
    onCountLine,
}: {
    count: number;
    onChange: (newValue: string) => void;
    onCountLine: () => void;
}) {
    const [internalCount, setInternalCount] = useState(count.toString());
    const [isCounted, setIsCounted] = useState(false);
    if (count === undefined) {
        return null;
    }

    return (
        <td className="atl-td atl-align-middle" colSpan={4}>
            <div
                className="atl-grid atl-gap-16 atl-items-center"
                style={{ gridTemplateColumns: '1fr 1fr' }}
            >
                <InlineMoneyField
                    aria-label={getMessage('text_count')}
                    formatOptions={{
                        style: 'decimal',
                        maximumFractionDigits: 5,
                        maximumSignificantDigits: 10,
                    }}
                    className="goldshark-count-input-mobile atl-w-full"
                    data-testid="stocktaking-count-value-input"
                    value={internalCount}
                    onChange={(newValue) => {
                        setInternalCount(newValue);
                    }}
                    onBlur={() => onChange(internalCount)}
                />
                {!isCounted ? (
                    <Button
                        data-testid="stocktaking-confirm-quantity-button"
                        className="atl-flex-1 atl-mr-2 atl-justify-center"
                        variant="secondary"
                        onClick={() => {
                            setIsCounted(true);
                            onCountLine();
                        }}
                    >
                        <Icon className="atl-text-blue-100 atl-font-bold">
                            done
                        </Icon>
                    </Button>
                ) : (
                    <div
                        className="atl-flex-1"
                        style={{ textAlignLast: 'center' }}
                    >
                        <Icon className="atl-text-green-100 atl-font-bold atl-ml-24">
                            done
                        </Icon>
                    </div>
                )}
            </div>
        </td>
    );
}

function MobileLocationRow({ locationName }: { locationName?: string }) {
    return (
        <div className="atl-flex atl-align-middle atl-items-center">
            {locationName && (
                <Icon className="atl-text-grey-60 atl-mr-8">location_on</Icon>
            )}
            {locationName}
        </div>
    );
}

function MobileEditableLocationCell({
    warehouseId,
    locationId,
    onChange,
}: {
    warehouseId: number;
    locationId?: number;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}) {
    return (
        <Dropdown
            data-testid="stocktaking-locations-dropdown"
            value={locationId}
            onChange={onChange}
            defaultValue={LOCATION_NOT_CHOSEN.id}
        >
            <DropdownOpener className=" atl-min-w-0" />
            <DropdownDrawer>
                <DropdownSearch />
                <DropdownScrollContainer>
                    <AsyncDropdownOptions
                        url={`${ApiUrl.INVENTORY_LOCATION_INTERNAL_SEARCH}?warehouseId=${warehouseId}`}
                    >
                        <Option value={LOCATION_NOT_CHOSEN.id}>
                            {getMessage(LOCATION_NOT_CHOSEN.displayName)}
                        </Option>
                    </AsyncDropdownOptions>
                </DropdownScrollContainer>
            </DropdownDrawer>
        </Dropdown>
    );
}

function MobileUnitCell({ unit }: { unit?: string }) {
    return (
        <td className="atl-td">
            <div className="atl-flex atl-flex-col">
                <div>{getMessage('text_unit')}</div>
                <div className="atl-text-grey-40">{unit ?? '-'}</div>
            </div>
        </td>
    );
}

function MobileCostPriceCell({ costPrice }: { costPrice: number }) {
    return (
        <td className="atl-td">
            <div className="atl-flex atl-flex-col">
                <div className="atl-whitespace-nowrap">
                    {getMessage('text_cost_price')}
                </div>
                <div className="atl-text-grey-40">
                    <NumberFormat
                        value={costPrice}
                        options={{
                            style: 'decimal',
                            maximumFractionDigits: 2,
                        }}
                    />
                </div>
            </div>
        </td>
    );
}

function MobileDeviationCell({ deviation }: { deviation: number }) {
    return (
        <td className="atl-td">
            <div className="atl-flex atl-flex-col">
                <div>{getMessage('text_deviation')}</div>
                <div className="atl-text-grey-40">
                    <NumberFormat
                        value={deviation}
                        options={{
                            style: 'decimal',
                            maximumFractionDigits: 2,
                        }}
                    />
                </div>
            </div>
        </td>
    );
}

function MobileValueCell({ value }: { value: number }) {
    return (
        <td className="atl-td">
            <div className="atl-flex atl-flex-col">
                <div>{getMessage('text_value')}</div>
                <div className="atl-text-grey-40">
                    <NumberFormat
                        value={value}
                        options={{
                            style: 'decimal',
                            maximumFractionDigits: 2,
                        }}
                    />
                </div>
            </div>
        </td>
    );
}

function MobileCommentRow({ comment }: { comment: string }) {
    return (
        <td className="atl-td atl-align-middle" colSpan={4}>
            <div className="atl-flex atl-gap-16 atl-items-center">
                <div>
                    <ChatIcon size="small" />
                </div>
                <div className={'atl-truncate'} style={{ width: '300px' }}>
                    {comment}
                </div>
            </div>
        </td>
    );
}
