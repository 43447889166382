import { useEffect, useState } from 'react';
import { useAuthConfig } from './useAuthConfig';
import { useUserInfo } from './useUserInfo';

export type UseSessionReturn = {
    isLoggedOut: boolean;
    isSuspended: boolean;
    loginUrl?: string;
};

export function useSession(): UseSessionReturn {
    const isLoggedOut = useIsLoggedOut();
    const isSuspended = useIsSuspended();
    const loginUrl = useLoginUrl();

    useSessionRefresh();

    return { isLoggedOut, isSuspended, loginUrl };
}

function useIsLoggedOut(): boolean {
    const { data, isLoading } = useUserInfo();
    const isLoggedIn = data !== undefined;
    const [isLoggedOut, setIsLoggedOut] = useState(false);

    // Don't change the state if we are updating the user info
    useEffect(() => {
        if (isLoading) {
            return;
        }

        setIsLoggedOut(!isLoggedIn);
    }, [isLoading, isLoggedIn]);

    return isLoggedOut;
}

function useIsSuspended(): boolean {
    const { isSuspended } = useUserInfo();

    return isSuspended;
}

function useLoginUrl(): string | undefined {
    const authConfig = useAuthConfig();

    if (!authConfig) {
        return;
    }

    return authConfig.loginUrl;
}

function useSessionRefresh() {
    const { refreshUserInfo } = useUserInfo();

    const handleUserActivity = () => {
        // If the user was previously inactive, refresh the session
        refreshUserInfo();
        removeActivityListeners();
    };

    const addActivityListeners = () => {
        document.addEventListener('click', handleUserActivity);
        document.addEventListener('keydown', handleUserActivity);
    };

    const removeActivityListeners = () => {
        document.removeEventListener('click', handleUserActivity);
        document.removeEventListener('keydown', handleUserActivity);
    };

    useEffect(() => {
        const intervalId = setInterval(
            () => {
                removeActivityListeners();
                addActivityListeners();
            },
            5 * 60 * 1000,
        );

        return () => {
            clearInterval(intervalId);
            removeActivityListeners();
        };
    }, []);
}
