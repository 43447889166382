import { PageError } from '@Component/PageLoader/PageError';
import { ProductOverviewTable } from '@Page/LogisticsV2/pages/Product/overview/ProductOverviewTable';
import { useProductOverview } from '@Page/LogisticsV2/pages/Product/overview/useProductOverview';
import {
    ProductOverviewDisplayOptions,
    ProductOverviewDisplayOptionsWithSupplier,
} from './DisplayOptions/ProductOverviewDisplayOptions';
import { ProductOverviewBatchActions } from './ProductOverviewBatchActions';
import { ProductOverviewFilters } from './ProductOverviewFilters';
import { ProductOverviewHeader } from './components';
import { useProductOverviewBulkActions } from './useProductOverviewBulkActions';

import {
    Button,
    DownloadIcon,
    Spinner,
    TableLayout,
    TableLayoutContent,
    TableLayoutTopbar,
} from '@tlx/atlas';
import { GetStartedBannerProductOverview } from '@Page/LogisticsV2/pages/Product/overview/Banner/ProductOverviewBanner';
import { PRODUCTS_WITHOUT_SUPPLIER } from '@Page/LogisticsV2/pages/Product/overview/utils';

export function ProductOverview() {
    const products = useProductOverview();
    const bulkActions = useProductOverviewBulkActions(
        !products.filters.supplierFilter.includes('') &&
            !products.filters.supplierFilter.includes(
                PRODUCTS_WITHOUT_SUPPLIER
            ),
        products.selectedIds,
        products.notDeletableIds,
        products.onChange
    );

    const supplierId =
        document.querySelector<HTMLInputElement>(
            '#customerIdForProductOverview'
        )?.value ?? null;

    const productLength = products.data?.length || 0;

    if (products.data === undefined) {
        if (products.error) {
            return <PageError />;
        }
        return null;
    }

    return (
        <form autoComplete="off" className="atl-flex atl-flex-col atl-gap-24">
            <ProductOverviewHeader productLength={productLength} />
            <GetStartedBannerProductOverview />
            <TableLayout>
                <ProductOverviewBatchActions
                    products={products}
                    bulkActions={bulkActions}
                />
                <TableLayoutTopbar hidden={products.selectedIds.length !== 0}>
                    <ProductOverviewFilters filters={products.filters} />
                    <div className="atl-mr-8">
                        {products.isExporting ? (
                            <Spinner size="small" />
                        ) : (
                            <Button
                                data-testid="product-overview-export"
                                aria-label="product-overview-export"
                                variant="icon"
                                onClick={() => products.onExport()}
                            >
                                <DownloadIcon />
                            </Button>
                        )}
                    </div>
                    <div className="atl-mr-8">
                        {!products.filters.supplierFilter.includes('') ||
                        supplierId !== null ? (
                            <ProductOverviewDisplayOptionsWithSupplier />
                        ) : (
                            <ProductOverviewDisplayOptions />
                        )}
                    </div>
                </TableLayoutTopbar>
                <TableLayoutContent
                    style={{
                        maxHeight: 'calc(100vh - 260px)',
                        overflowY: 'auto',
                    }}
                >
                    <ProductOverviewTable
                        products={products}
                        filters={products.filters}
                    />
                </TableLayoutContent>
            </TableLayout>
        </form>
    );
}
