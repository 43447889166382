import * as React from 'react';
import { UserFeedbackButton } from '@Page/Automation2022/components/FeedbackButton/UserFeedbackButton';
import { UserFeedbackBox } from '@Page/Automation2022/components/FeedbackButton/UserFeedbackBox';
import { Team } from '@General/Team';
import { renderComponent } from '@General/renderComponent';
import { CustomerCategoryEnum } from '@General/types/customerCategory.enum';
import { CSSProperties } from 'react';
import { useResizeObserver } from '../../../hooks/useResizeObserver';

type UserFeedbackProps = {
    pageTitle: string;
    className?: string;
    style?: CSSProperties;
    isTertiary?: boolean;
    hideIcon?: boolean;
};
export const UserFeedback = ({
    pageTitle,
    className,
    style,
    isTertiary,
    hideIcon,
}: UserFeedbackProps) => {
    const [isFeedbackBoxOpen, setIsFeedbackBoxOpen] = React.useState(false);
    const { ref, size } = useResizeObserver();
    const feedbackFullView = size === undefined || size.width > 760;
    const feedbackButtonRef = React.useRef<HTMLButtonElement>(null);

    if (isTestAccount()) {
        return null;
    }

    return (
        <div className={className} ref={ref} style={style}>
            <UserFeedbackButton
                setIsFeedbackBoxOpen={setIsFeedbackBoxOpen}
                buttonRef={feedbackButtonRef}
                isTertiary={isTertiary}
                hideIcon={hideIcon}
            />
            {isFeedbackBoxOpen && (
                <UserFeedbackBox
                    pageTitle={pageTitle}
                    setIsFeedbackBoxOpen={setIsFeedbackBoxOpen}
                    feedbackFullView={feedbackFullView}
                    parent={feedbackButtonRef}
                    team={Team.SHEET_STORM}
                />
            )}
        </div>
    );
};

export function renderFeedbackButton(props: UserFeedbackProps, domId: string) {
    renderComponent(UserFeedback, domId, props);
}

function isTestAccount() {
    return (
        window?.segmentationData?.tripletexCustomerCategoryId3 ===
        CustomerCategoryEnum.TRIPLETEX_TEST
    );
}
