import {
    APIError,
    ResponseWrapper,
    createAPIRequest,
    createAPIResponse,
} from '@tlx/astro-shared';
import { SupportedLocale } from '@tlx/intl';
import useSWR from 'swr';

type LoggedInUserInfoDTO = {
    employeeId: number;
    companyId: number;
    language: SupportedLocale;
    loggedInWithConnect: boolean;
};

const COMPANY_SUSPENDED_CODES = [1004, 1005];

export type UseUserInfoReturn = {
    data?: LoggedInUserInfoDTO;
    isLoading: boolean;
    isSuspended: boolean;
    refreshUserInfo: () => void;
};

async function fetcher(url: string) {
    const request = createAPIRequest(url);
    const response = await window.fetch(request);
    const data = await createAPIResponse<ResponseWrapper<LoggedInUserInfoDTO>>(
        request,
        response,
    );

    return data?.value;
}

export function useUserInfo(): UseUserInfoReturn {
    const response = useSWR<LoggedInUserInfoDTO, Error>(
        '/v2/token/session/>whoAmI?refreshSession=true',
        fetcher,
    );

    const error = response.error;
    const data = error !== undefined ? undefined : response.data;
    const isLoading = response.isValidating;
    const refreshUserInfo = response.mutate;

    const isSuspended =
        error instanceof APIError &&
        COMPANY_SUSPENDED_CODES.includes(error.details.code);

    return {
        data,
        isLoading,
        isSuspended,
        refreshUserInfo,
    };
}
