import { NumberFormat } from '@Page/Salary5/Components/NumberFormat/NumberFormat';
import {
    ApprovedIcon,
    Button,
    Dropdown,
    DropdownDrawer,
    DropdownOpener,
    DropdownScrollContainer,
    DropdownSearch,
    Icon,
    InlineMoneyField,
    KebabIcon,
    Option,
    PopupMenu,
    PopupMenuItemButton,
    Textarea,
    usePopupMenu,
} from '@tlx/atlas';
import {
    formatTimeStamp,
    getStocktakingValue,
    LOCATION_NOT_CHOSEN,
    ProductLineDTO,
} from '@Page/LogisticsV2/pages/Stocktaking/utils';
import { LogisticsModal } from '@Page/LogisticsV2/components/Modals/Modal';
import React, { useEffect, useState } from 'react';
import {
    getDisplayNumber,
    getValidationMessagesFromResponse,
} from '@Page/LogisticsV2/utils/functions';
import {
    doChangeLocation,
    doCountLine,
    doUnCountLine,
    doUpdateLine,
} from '@Page/LogisticsV2/pages/Stocktaking/details2/StocktakingDetailsActions';
import { ApiUrl, AutoSaveStatusType } from '@Page/LogisticsV2/utils/enums';
import { AsyncDropdownOptions } from '@tlx/astro-shared';
import { useStocktakingDetails } from '@Page/LogisticsV2/pages/Stocktaking/details2/useStocktakingDetails';
import { debounce } from 'lodash';

export function StocktakingDetailsTableRow({
    columns,
    line,
    onChange,
}: {
    columns: string[];
    line: ProductLineDTO;
    onChange: (
        status: AutoSaveStatusType,
        productlineId?: number,
        shouldAnimate?: boolean
    ) => void;
}) {
    const stocktaking = useStocktakingDetails();
    const [editLocation, setEditLocation] = useState(false);
    const handleChangeLine = async (value: string, type: string) => {
        onChange(AutoSaveStatusType.PENDING);
        const { error } = await doUpdateLine(
            line.id,
            type === 'count' ? value : undefined,
            type === 'comment' ? value : undefined
        );
        if (error === undefined) {
            onChange(AutoSaveStatusType.SAVED);
            await stocktaking.mutate();
        } else {
            onChange(AutoSaveStatusType.ERROR);
            getValidationMessagesFromResponse(
                error,
                type === 'count'
                    ? getMessage('validation_cannot_save_count')
                    : ''
            );
        }
    };

    const handleCountLine = async () => {
        onChange(AutoSaveStatusType.PENDING);
        const { error } = await doCountLine(line.id);
        if (error === undefined) {
            onChange(AutoSaveStatusType.SAVED, line.id, true);
        } else {
            onChange(AutoSaveStatusType.ERROR);
            getValidationMessagesFromResponse(
                error,
                getMessage('validation_cannot_count_line')
            );
        }
    };

    const debouncedHandleCountLine = debounce(handleCountLine, 1000);

    const handleUnCountLine = async () => {
        onChange(AutoSaveStatusType.PENDING);
        const { error } = await doUnCountLine(line.id);
        if (error === undefined) {
            onChange(AutoSaveStatusType.SAVED);
        } else {
            onChange(AutoSaveStatusType.ERROR);
            getValidationMessagesFromResponse(
                error,
                getMessage('validation_cannot_uncount_line')
            );
        }
    };

    const handleChangeLocation = async (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        onChange(AutoSaveStatusType.PENDING);
        const { error } = await doChangeLocation(line.id, event.target.value);
        if (error === undefined) {
            onChange(AutoSaveStatusType.SAVED);
            if (event.target.value !== LOCATION_NOT_CHOSEN.id) {
                setEditLocation(false);
            }
        } else {
            onChange(AutoSaveStatusType.ERROR);
        }
    };

    if (stocktaking.data === undefined) {
        return null;
    }

    return (
        <tr className={`atl-tr row-${line.id}`}>
            <NumberRow productNumber={getDisplayNumber(line.product)} />
            <NameRow
                productName={line.product.name}
                productId={line.product.id}
            />
            {line.counted ? (
                <CountedRow count={line.count} />
            ) : (
                <NotCountedRow
                    count={line.count}
                    onChange={(value) => handleChangeLine(value, 'count')}
                    onCountLine={debouncedHandleCountLine}
                />
            )}
            {columns.map((column) => (
                <StocktakingDetailsTableRowCell
                    key={column}
                    column={column}
                    line={line}
                    warehouseId={stocktaking.data?.inventory?.id ?? 0}
                    editLocation={editLocation}
                    onChange={handleChangeLocation}
                />
            ))}
            <ConfirmedRow
                name={`${line.counter?.firstName ?? ''} ${
                    line.counter?.lastName ?? ''
                }`}
                dateCounted={line.dateCounted}
            />
            <ActionsRow
                line={line}
                isStocktakingCompleted={stocktaking.data.isCompleted}
                onEditLocation={() => setEditLocation(true)}
                onUnCountLine={handleUnCountLine}
                onUpdateComment={(value) => handleChangeLine(value, 'comment')}
            />
        </tr>
    );
}

function StocktakingDetailsTableRowCell({
    column,
    line,
    warehouseId,
    editLocation,
    onChange,
}: {
    column: string;
    line: ProductLineDTO;
    warehouseId: number;
    editLocation: boolean;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}): JSX.Element {
    const englishLanguage = window.locale.includes('en');
    switch (column) {
        case 'unit':
            return (
                <UnitRow
                    unit={
                        line?.product?.productUnit
                            ? englishLanguage
                                ? line.product?.productUnit?.nameShortEN ||
                                  line.product?.productUnit?.nameEN
                                : line.product?.productUnit?.nameShort
                            : '-'
                    }
                />
            );
        case 'deviation':
            return <DeviationRow deviation={line.count - line.expectedStock} />;
        case 'costPrice':
            return (
                <CostPriceRow
                    costPrice={
                        line.counted
                            ? line.unitCostCurrency
                            : line.product.costExcludingVatCurrency
                    }
                />
            );
        case 'value':
            return <ValueRow value={getStocktakingValue(line)} />;
        case 'location':
            return (
                <td className="atl-td atl-align-middle gs-stocktaking-dropdown">
                    {((!line.location && !line.counted) || editLocation) && (
                        <EditableLocationRow
                            warehouseId={warehouseId}
                            locationId={line.location?.id}
                            onChange={onChange}
                        />
                    )}
                    {line.location && !editLocation && (
                        <LocationRow locationName={line.location.displayName} />
                    )}
                </td>
            );
        case 'comment':
            return <CommentRow comment={line.comment} />;
        default:
            throw new Error(`Unknown column: ${column}`);
    }
}

function NumberRow({ productNumber }: { productNumber?: string }) {
    return <td className="atl-td atl-align-middle">{productNumber}</td>;
}

function NameRow({
    productName,
    productId,
}: {
    productName: string;
    productId: number;
}) {
    return (
        <td className="atl-td atl-align-middle">
            <a
                href={addContextId(
                    `/execute/productMenu?productId=${productId}`
                )}
                target="_blank"
            >
                {productName}
            </a>
        </td>
    );
}

function CountedRow({ count }: { count: number }) {
    return (
        <td className="atl-td atl-align-middle atl-items-center atl-text-center">
            <div className="atl-text-right" style={{ width: '56%' }}>
                <NumberFormat
                    value={count}
                    options={{
                        style: 'decimal',
                        maximumFractionDigits: 2,
                    }}
                />
            </div>
        </td>
    );
}

function NotCountedRow({
    count,
    onChange,
    onCountLine,
}: {
    count: number;
    onChange: (newValue: string) => void;
    onCountLine: () => void;
}) {
    const [internalCount, setInternalCount] = useState(count.toString());
    const [isCounted, setIsCounted] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        setInternalCount(count.toString());
    }, [count]);

    if (count === undefined) {
        return null;
    }

    return (
        <td
            className="atl-td atl-align-middle atl-items-center atl-text-center"
            style={{ minWidth: '200px' }}
        >
            <div className="atl-flex atl-gap-16 atl-items-center">
                <InlineMoneyField
                    aria-label={getMessage('text_count')}
                    formatOptions={{
                        style: 'decimal',
                        maximumFractionDigits: 5,
                        maximumSignificantDigits: 10,
                    }}
                    data-testid="stocktaking-count-value-input"
                    className={'goldshark-stocktacking-count-field'}
                    value={internalCount}
                    onChange={(newValue) => {
                        if (isNaN(Number(newValue))) {
                            setError(true);
                        } else {
                            setError(false);
                        }
                        setInternalCount(newValue);
                    }}
                    onBlur={() => onChange(internalCount)}
                />
                {!isCounted ? (
                    <Button
                        data-testid="stocktaking-confirm-quantity-button"
                        variant="secondary"
                        onClick={() => {
                            if (!error) {
                                setIsCounted(true);
                                onCountLine();
                            }
                        }}
                    >
                        {getMessage('text_confirm')}
                    </Button>
                ) : (
                    <Icon className="atl-text-green-100 atl-font-bold atl-ml-24">
                        done
                    </Icon>
                )}
            </div>
        </td>
    );
}

function UnitRow({ unit }: { unit?: string }) {
    return <td className="atl-td atl-align-middle">{unit ?? '-'}</td>;
}

function DeviationRow({ deviation }: { deviation: number }) {
    return (
        <td className="atl-td atl-align-middle atl-text-right">
            <NumberFormat
                value={deviation}
                options={{
                    style: 'decimal',
                    maximumFractionDigits: 2,
                }}
            />
        </td>
    );
}

function CostPriceRow({ costPrice }: { costPrice: number }) {
    return (
        <td className="atl-td atl-align-middle atl-text-right">
            <NumberFormat
                value={costPrice}
                options={{
                    style: 'decimal',
                    maximumFractionDigits: 2,
                }}
            />
        </td>
    );
}

function ValueRow({ value }: { value: number }) {
    return (
        <td className="atl-td atl-align-middle atl-text-right">
            <NumberFormat
                value={value}
                options={{
                    style: 'decimal',
                    maximumFractionDigits: 2,
                }}
            />
        </td>
    );
}

function LocationRow({ locationName }: { locationName?: string }) {
    if (locationName === undefined) {
        return null;
    }
    return (
        <div className="atl-flex atl-align-middle atl-items-center atl-w-full">
            <Icon className="atl-text-grey-60 atl-mr-8">location_on</Icon>
            <div className="atl-whitespace-nowrap">{locationName}</div>
        </div>
    );
}

function EditableLocationRow({
    warehouseId,
    locationId,
    onChange,
}: {
    warehouseId: number;
    locationId?: number;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}) {
    return (
        <Dropdown
            data-testid="stocktaking-locations-dropdown"
            value={locationId}
            onChange={onChange}
            defaultValue={LOCATION_NOT_CHOSEN.id}
        >
            <DropdownOpener className="atl-max-h-full" />
            <DropdownDrawer>
                <DropdownSearch />
                <DropdownScrollContainer>
                    <AsyncDropdownOptions
                        url={`${ApiUrl.INVENTORY_LOCATION_INTERNAL_SEARCH}?warehouseId=${warehouseId}`}
                    >
                        <Option value={LOCATION_NOT_CHOSEN.id}>
                            {getMessage(LOCATION_NOT_CHOSEN.displayName)}
                        </Option>
                    </AsyncDropdownOptions>
                </DropdownScrollContainer>
            </DropdownDrawer>
        </Dropdown>
    );
}

function CommentRow({ comment }: { comment: string }) {
    return (
        <td className="atl-td atl-align-middle">
            {comment ? (
                <div className="atl-truncate" style={{ width: '300px' }}>
                    {comment}
                </div>
            ) : (
                '-'
            )}
        </td>
    );
}

function ConfirmedRow({
    name,
    dateCounted,
}: {
    name: string;
    dateCounted?: string;
}) {
    return (
        <td className="atl-td atl-whitespace-nowrap atl-align-middle">
            <div className={'atl-flex atl-gap-8 atl-items-center'}>
                {name.length > 1 && (
                    <div>
                        <ApprovedIcon
                            className="atl-p-2 atl-rounded-full atl-bg-turquoise-20"
                            size="small"
                        />
                    </div>
                )}
                <span>{name}</span>
                <span>{formatTimeStamp(dateCounted ?? '')}</span>
            </div>
        </td>
    );
}

function ActionsRow({
    line,
    isStocktakingCompleted,
    onEditLocation,
    onUnCountLine,
    onUpdateComment,
}: {
    line: ProductLineDTO;
    isStocktakingCompleted: boolean;
    onEditLocation: () => void;
    onUnCountLine: () => void;
    onUpdateComment: (value: string) => void;
}) {
    const [showComment, setShowComment] = useState(false);
    const [internalComment, setInternalComment] = useState(line.comment ?? '');
    const { openerButtonProps, popupMenuProps } = usePopupMenu();
    return (
        <td
            className="atl-td atl-td--button"
            style={{
                alignContent: 'center',
            }}
        >
            <div>
                <Button
                    {...openerButtonProps}
                    data-testid="open-button"
                    variant="icon"
                    aria-label="stocktaking-row-action-button"
                >
                    <KebabIcon />
                </Button>
                <PopupMenu {...popupMenuProps} className={'atl-bg-white'}>
                    <PopupMenuItemButton
                        data-trackingid={'text_comment'}
                        data-testid={'stocktaking-comment'}
                        onClick={() => setShowComment(true)}
                    >
                        {getMessage('text_comment')}
                    </PopupMenuItemButton>
                    {!line.counted &&
                        isLocationEnabled &&
                        !isStocktakingCompleted && (
                            <PopupMenuItemButton
                                data-trackingid={'text_edit_location'}
                                data-testid={'stocktaking-edit-location'}
                                onClick={onEditLocation}
                            >
                                {getMessage('text_edit_location')}
                            </PopupMenuItemButton>
                        )}
                    {line.counted && !isStocktakingCompleted && (
                        <PopupMenuItemButton
                            data-testid={'stocktaking-uncount-line'}
                            data-trackingid={'text_inventory_uncount_line'}
                            onClick={onUnCountLine}
                        >
                            {getMessage('text_inventory_uncount_line')}
                        </PopupMenuItemButton>
                    )}
                </PopupMenu>
            </div>
            {showComment && (
                <LogisticsModal
                    title={getMessage('text_comment')}
                    svgIcon="comment"
                    onCancel={() => setShowComment(false)}
                    onConfirm={() => {
                        setShowComment(false);
                        onUpdateComment(internalComment);
                    }}
                >
                    <Textarea
                        data-testid="modal-comment-textarea"
                        className={
                            'atl-flex atl-gap-8 atl-mb-16 goldshark-comment-modal-textarea'
                        }
                        placeholder={getMessage('text_add_comment')}
                        value={internalComment}
                        onChange={(e) => setInternalComment(e.target.value)}
                    />
                </LogisticsModal>
            )}
        </td>
    );
}
