import {
    Button,
    SkeletonRow,
    SkeletonText,
    TableLayout,
    TableLayoutContent,
    TableLayoutTopbar,
} from '@tlx/atlas';
import {
    Settings,
    TableColumnSetting,
    TableColumnsSetting,
    useTableColumnsSetting,
} from '@tlx/astro-shared';
import { getStocktakingDetailsDisplayOptionsName } from '@Page/LogisticsV2/pages/Stocktaking/details2/StocktakingDetailsDisplayOptions';
import {
    getAvailableColumnsInStocktakingDetails,
    getColumnLabel,
    getDefaultColumnsInStocktakingDetails,
} from '@Page/LogisticsV2/pages/Stocktaking/details2/utils';
import React, { useState } from 'react';
import { StocktakingDetailsMobileTableFilters } from '@Page/LogisticsV2/pages/Stocktaking/details2/table/StocktakingDetailsTableFilters';
import { useStocktakingDetailsTableData } from '@Page/LogisticsV2/pages/Stocktaking/details2/useStocktakingDetailsTableData';
import { useLoadMoreTarget } from '@Page/LogisticsV2/hooks/fetcher';
import { StocktakingDetailsMobileHeader } from '@Page/LogisticsV2/pages/Stocktaking/details2/mobile/StocktakingDetailsMobileHeaderComponents';
import { StocktakingDetailsMobileBody } from '@Page/LogisticsV2/pages/Stocktaking/details2/mobile/StocktakingDetailsMobileRowComponents';
import {
    AutoSaveStatusType,
    MethodType,
    PageUrl,
} from '@Page/LogisticsV2/utils/enums';
import {
    doCreateLines,
    getIsFiltered,
} from '@Page/LogisticsV2/pages/Stocktaking/details2/StocktakingDetailsActions';
import { NoResultsEmptyState } from '@Page/LogisticsV2/components/EmptyState/EmptyState';
import { ProductLinesAPIModel } from '@Page/LogisticsV2/pages/Stocktaking/details2/types';
import { TabEmptyState } from '@Page/LogisticsV2/pages/Stocktaking/details2/table/StocktakingDetailsTableComponents';
import { useStocktakingDetails } from '@Page/LogisticsV2/pages/Stocktaking/details2/useStocktakingDetails';
import { ProductSelectorModal } from '@Page/Goldshark/Components/Modal/ProductSelectorModal/ProductSelectorModal';
import { invokeAPI } from '@Page/Goldshark/API/api';
import { ApiURL } from '@Page/Goldshark/API/url';
import {
    ResaleProductDTO,
    StocktakingDTO,
} from '@Page/LogisticsV2/types/dtoTypes';
import { mapProductsToProductLines } from '@Page/LogisticsV2/utils/mappers';
import { getValidationMessagesFromResponse } from '@Page/LogisticsV2/utils/functions';

export function StocktakingDetailsMobileTableWithFilters({
    stocktaking,
    onChange,
    onClick,
    modalProps,
}: {
    onChange: (status: AutoSaveStatusType) => void;
    onClick: React.MouseEventHandler<HTMLElement>;
    modalProps: {
        open: boolean;
        onClose: () => void;
    };
    stocktaking: StocktakingDTO;
}) {
    const productlines = useStocktakingDetailsTableData();
    const [editLocation, setEditLocation] = useState(false);
    const enabledColumnsForStocktakingDetails = useTableColumnsSetting(
        getStocktakingDetailsDisplayOptionsName(),
        getDefaultColumnsInStocktakingDetails()
    );
    const handleProductSelectorConfirm = async (
        products: Record<string, string>
    ) => {
        const fields =
            'id,name,number,elNumber,nrfNumber,ean,costExcludingVatCurrency,stockOfGoods';
        const productIds = Object.keys(products).map((id) => {
            return { id: id };
        });
        const { data } = await invokeAPI(
            `${ApiURL.PRODUCT_GET_BY_ID}?fields=${fields}`,
            MethodType.POST,
            JSON.stringify(productIds)
        );
        data.map((product: ResaleProductDTO) => {
            if (products[product.id]) {
                product.count = products[product.id];
            }
        });
        await handleOnConfirm(data as ResaleProductDTO[]);
    };
    async function handleOnConfirm(selectedProducts: ResaleProductDTO[]) {
        onChange(AutoSaveStatusType.PENDING);
        const newProductLines = mapProductsToProductLines(
            selectedProducts,
            stocktaking
        );
        const { error } = await doCreateLines(newProductLines);
        if (error) {
            onChange(AutoSaveStatusType.ERROR);
            getValidationMessagesFromResponse(
                error,
                getMessage('validation_cannot_add_new_productLines')
            );
        } else {
            onChange(AutoSaveStatusType.SAVED);
        }
    }
    return (
        <TableLayout>
            <TableLayoutTopbar className="atl-justify-between atl-pr-8">
                <StocktakingDetailsMobileTableFilters warehouseId={0} />
                <div className={'atl-mr-8'}>
                    {!stocktaking.isCompleted && (
                        <div>
                            <Button
                                data-testid="add-product"
                                variant="secondary"
                                onClick={onClick}
                            >
                                {getMessage('text_add_product')}
                            </Button>
                            <ProductSelectorModal
                                modalProps={modalProps}
                                isDirect={true}
                                hideCount={true}
                                onConfirm={handleProductSelectorConfirm}
                            />
                        </div>
                    )}
                </div>
                <Settings>
                    <TableColumnsSetting
                        name={getStocktakingDetailsDisplayOptionsName()}
                        label={getMessage('text_columns')}
                        aria-label="columns-for-stocktaking-details"
                        defaultColumns={getDefaultColumnsInStocktakingDetails()}
                    >
                        {getAvailableColumnsInStocktakingDetails().map(
                            (column) => (
                                <TableColumnSetting key={column} value={column}>
                                    {getColumnLabel(column)}
                                </TableColumnSetting>
                            )
                        )}
                    </TableColumnsSetting>
                </Settings>
            </TableLayoutTopbar>
            <TableLayoutContent
                style={{
                    maxHeight: 'calc(100vh - 500px)',
                }}
            >
                {productlines.data.length > 0 &&
                    productlines.data.map((productline) => (
                        <table className="atl-table" key={productline.id}>
                            <StocktakingDetailsMobileHeader
                                line={productline}
                                onChange={onChange}
                                onEditLocation={setEditLocation}
                            />
                            <StocktakingDetailsMobileBody
                                line={productline}
                                editLocation={editLocation}
                                columns={enabledColumnsForStocktakingDetails}
                                onChange={onChange}
                            />
                        </table>
                    ))}
                <StocktakingDetailsMobileTableEmptyStatesAndSkeletons
                    productlines={productlines}
                />
            </TableLayoutContent>
        </TableLayout>
    );
}

function StocktakingDetailsMobileTableEmptyStatesAndSkeletons({
    productlines,
}: {
    productlines: ProductLinesAPIModel;
}) {
    const loadMoreRef = useLoadMoreTarget<HTMLDivElement>(
        productlines.loadMore
    );
    const { data: stocktaking } = useStocktakingDetails();
    if (!stocktaking) {
        return null;
    }
    return (
        <table className="atl-table">
            <tbody className="atl-body">
                {productlines.data.length === 0 && !productlines.isLoading ? (
                    getIsFiltered() ? (
                        <NoResultsEmptyState
                            colSpan={3}
                            onClick={() =>
                                window.history.pushState(
                                    {},
                                    '',
                                    addContextId(
                                        `${PageUrl.STOCKTAKING_DETAILS}?inventoryId=${stocktaking.id}`
                                    )
                                )
                            }
                        />
                    ) : (
                        <tr>
                            <td colSpan={3}>
                                <TabEmptyState>
                                    {!stocktaking.isCompleted && (
                                        <div>
                                            <Button
                                                data-testid="add-product"
                                                variant="secondary"
                                                className="goldshark-stocktaking-details__add-button"
                                                type="submit"
                                                form="product-selector-trigger-modal"
                                            >
                                                {getMessage('text_add_product')}
                                            </Button>
                                        </div>
                                    )}
                                </TabEmptyState>
                            </td>
                        </tr>
                    )
                ) : null}
                {productlines.isLoading && (
                    <SkeletonRow>
                        {[...Array.from(Array(3).keys())].map((index) => (
                            <td className="atl-td" key={index} colSpan={3}>
                                <SkeletonText className="atl-w-full" />
                            </td>
                        ))}
                    </SkeletonRow>
                )}
                {productlines.hasMore && (
                    <tr>
                        <td>
                            <div ref={loadMoreRef} />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}
