import React, { useEffect, useState } from 'react';
import { AutoSaveStatusType } from '@Page/LogisticsV2/utils/enums';
import {
    OptionalComponent,
    StocktakingDetailsDesktopLayout,
    StocktakingDetailsMobileLayout,
    StocktakingDetailsTableLayout,
} from '@Page/LogisticsV2/pages/Stocktaking/details2/StocktakingDetailsComponents';
import { useStocktakingDetails } from '@Page/LogisticsV2/pages/Stocktaking/details2/useStocktakingDetails';
import { StocktakingDetailsHeader } from '@Page/LogisticsV2/pages/Stocktaking/details2/header/StocktakingDetailsHeaderComponents';
import { AutoSaveStatus } from '@Page/LogisticsV2/components/AutoSaveStatus/AutoSaveStatus';
import {
    StocktakingDetailsCompletedSummary,
    StocktakingDetailsInProgressSummary,
    StocktakingDetailsSummaryLayout,
} from '@Page/LogisticsV2/pages/Stocktaking/details2/header/StocktakingDetailsSummaryComponents';
import useLocalStorage from '../../../../../util/storage';
import { useDeviceType } from '@Page/LogisticsV2/hooks/useDeviceType';
import { StocktakingDetailsTabs } from '@Page/LogisticsV2/pages/Stocktaking/details2/table/StocktakingDetailsTableComponents';
import { StocktakingDetailsTableWithFilters } from '@Page/LogisticsV2/pages/Stocktaking/details2/table/StocktakingDetailsTableWithFilters';
import { StocktakingDetailsBarcodeTable } from '@Page/LogisticsV2/pages/Stocktaking/details2/table/StocktakingDetailsBarcodeTable';
import './StocktakingDetails.css';
import { useStocktakingDetailsTableData } from '@Page/LogisticsV2/pages/Stocktaking/details2/useStocktakingDetailsTableData';
import { StocktakingDetailsMobileTableWithFilters } from '@Page/LogisticsV2/pages/Stocktaking/details2/mobile/StocktakingDetailsMobileTableWithFilters';
import { StocktakingDetailsMobileTableLayout } from '@Page/LogisticsV2/pages/Stocktaking/details2/mobile/StocktakingDetailsMobileComponents';
import { StocktakingDetailsMobileBarcodeTable } from '@Page/LogisticsV2/pages/Stocktaking/details2/mobile/StocktakingDetailsMobileBarcodeTable';
import { useStocktakingDetailsKeyboard } from '@Page/LogisticsV2/pages/Stocktaking/details2/useStocktakingDetailsKeyboard';
import useSessionStorage from '@Page/LogisticsV2/hooks/useSessionStorage';
import { ProductLineStatus } from '@Page/LogisticsV2/pages/Stocktaking/utils';
import { useModal } from '@tlx/atlas';

export function StocktakingDetailsPage() {
    const [status, setStatus] = useState(AutoSaveStatusType.SAVED);
    const stocktaking = useStocktakingDetails();
    const { refresh } = useStocktakingDetailsTableData();
    const { modalProps, openerProps } = useModal();
    const device = useDeviceType();
    const [isBarcodeMode, setIsBarcodeMode] = useLocalStorage(
        `stocktakingBarcodeMode-${window.loginEmployeeId}`,
        false
    );
    const [, setStatusFilter] = useSessionStorage(
        `stocktakingDetailsProductLineStatus-${window.loginEmployeeId}`,
        ProductLineStatus.ALL_PRODUCTS
    );
    useStocktakingDetailsKeyboard();

    useEffect(() => {
        setIsBarcodeMode(false);
        setStatusFilter(ProductLineStatus.ALL_PRODUCTS);
    }, [stocktaking.data?.id]);

    const handleOnChange = async (status: AutoSaveStatusType) => {
        setStatus(status);
        if (status !== AutoSaveStatusType.PENDING && !isBarcodeMode) {
            await refresh();
            await stocktaking.mutate();
        }
    };

    const StocktakingDetailsLayout =
        device === 'desktop'
            ? StocktakingDetailsDesktopLayout
            : StocktakingDetailsMobileLayout;

    if (stocktaking.data === undefined) {
        return null;
    }

    return (
        <StocktakingDetailsLayout>
            <StocktakingDetailsHeader
                stocktaking={stocktaking.data}
                onChange={handleOnChange}
            >
                {!stocktaking.data.isCompleted && (
                    <AutoSaveStatus status={status} />
                )}
            </StocktakingDetailsHeader>
            <StocktakingDetailsSummaryLayout>
                {stocktaking.data.isCompleted ? (
                    <StocktakingDetailsCompletedSummary
                        stocktaking={stocktaking.data}
                    />
                ) : (
                    <StocktakingDetailsInProgressSummary
                        stocktaking={stocktaking.data}
                        onChange={handleOnChange}
                    />
                )}
                {stocktaking.data.comment.length > 0 && (
                    <div className="atl-flex atl-p-8 atl-bg-bg atl-rounded">
                        {stocktaking.data.comment}
                    </div>
                )}
            </StocktakingDetailsSummaryLayout>
            <OptionalComponent id={'desktop'} deviceType={device}>
                {isBarcodeMode ? (
                    <StocktakingDetailsBarcodeTable onChange={handleOnChange} />
                ) : (
                    <StocktakingDetailsTableLayout>
                        {!stocktaking.data.isCompleted && (
                            <StocktakingDetailsTabs />
                        )}
                        <StocktakingDetailsTableWithFilters
                            stocktaking={stocktaking.data}
                            onChange={handleOnChange}
                        />
                    </StocktakingDetailsTableLayout>
                )}
            </OptionalComponent>
            <OptionalComponent id={'mobile'} deviceType={device}>
                {isBarcodeMode ? (
                    <StocktakingDetailsMobileBarcodeTable
                        onChange={handleOnChange}
                    />
                ) : (
                    <StocktakingDetailsMobileTableLayout>
                        {!stocktaking.data.isCompleted && (
                            <StocktakingDetailsTabs />
                        )}
                        <StocktakingDetailsMobileTableWithFilters
                            stocktaking={stocktaking.data}
                            modalProps={modalProps}
                            onChange={handleOnChange}
                            onClick={openerProps.onClick}
                        />
                    </StocktakingDetailsMobileTableLayout>
                )}
            </OptionalComponent>
        </StocktakingDetailsLayout>
    );
}
