import React, { KeyboardEvent, useState } from 'react';
import Draggable from 'react-draggable';
import { flip, offset, shift } from '@floating-ui/react-dom';

import { Team } from '@General/Team';

import {
    FloatingSurface,
    FloatingSurfaceBackdrop,
    FloatingSurfaceProps,
} from '@Page/Report/Component/FloatingSurface';

import { useSendUserFeedback } from '@Page/Automation2022/hooks/dataFetching/useSendUserFeedback';
import { UserFeedbackConfirmation } from '@Page/Automation2022/components/FeedbackButton/UserFeedbackConfirmation';
import { UserFeedbackInput } from '@Page/Automation2022/components/FeedbackButton/UserFeedbackInput';
import { UserFeedbackSendCancel } from '@Page/Automation2022/components/FeedbackButton/UserFeedbackSendCancel';
import { UserFeedbackQuestionField } from '@Page/Automation2022/components/FeedbackButton/UserFeedbackQuestionField';
import { useResizeObserver } from '../../../../hooks/useResizeObserver';
import useLocalStorage from '../../../../util/storage';

const wideScreen: FloatingSurfaceProps['options'] = {
    placement: 'bottom-end',
    strategy: 'fixed',
    middleware: [
        offset(4),
        shift({
            padding: 4,
        }),
        flip(),
    ],
};

const narrowScreen: FloatingSurfaceProps['options'] = {
    placement: 'bottom',
    strategy: 'fixed',
    middleware: [offset(4), shift(), flip()],
};

const useFloatingSurfaceOptions: () => FloatingSurfaceProps['options'] = () => {
    const { width } = document.body.getBoundingClientRect();

    if (width <= 480) {
        return narrowScreen;
    } else {
        return wideScreen;
    }
};

interface UserFeedbackBoxProps {
    readonly pageTitle: string;
    readonly setIsFeedbackBoxOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    readonly feedbackFullView: boolean;
    readonly team: Team;
    readonly parent: React.RefObject<HTMLElement>;
    readonly showThisIsNotSupportInfo?: boolean;
}

export function UserFeedbackBox({
    pageTitle,
    setIsFeedbackBoxOpen,
    feedbackFullView,
    team,
    parent,
    showThisIsNotSupportInfo,
}: UserFeedbackBoxProps) {
    const [feedbackIsSent, setFeedbackIsSent] = useState(false);
    const { sendToBackend, sendToBackendError } = useSendUserFeedback();
    const [input, setInput] = useState('');
    const [contactRequestLocalStorage, setContactRequestLocalStorage] =
        useLocalStorage<boolean | undefined>('contact', undefined);
    const { ref, size } = useResizeObserver();
    const [contactRequest, setContactRequest] = useState(
        contactRequestLocalStorage
    );

    const resetFeedbackBox = () => {
        setFeedbackIsSent(true);
        setTimeout(() => {
            setIsFeedbackBoxOpen(false);
            setFeedbackIsSent(false);
        }, 2000);
    };

    const sendFeedback = async (input: string) => {
        setContactRequestLocalStorage(contactRequest ?? true);

        try {
            await sendToBackend({
                message: input,
                team,
                pageTitle: pageTitle,
                contactRequest: contactRequest ?? true,
            });
        } catch (error) {
            console.error(error);
        }
        return {
            error: { sendToBackendError },
        };
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (
            (event.metaKey || event.ctrlKey) &&
            event.key == 'Enter' &&
            isSendButtonClickable
        ) {
            resetFeedbackBox();
            void sendFeedback(input);
        }
        if (event.key == 'Escape') {
            setIsFeedbackBoxOpen(false);
        }
    };

    const isSendButtonClickable = input.length > 0;
    const showNotSupportInfo = showThisIsNotSupportInfo || input.includes('?');
    const letterCounter = input.split('').length + '/500';

    return (
        <FloatingSurfaceBackdrop passive>
            <FloatingSurface
                openerRef={parent}
                options={useFloatingSurfaceOptions()}
            >
                <Draggable cancel={'.atl-input, .atl-button'}>
                    <div
                        className={`feedbackButton-borderBox atl-bg-white atl-px-32 atl-pb-24               
                   ${
                       feedbackFullView
                           ? 'feedbackBoxFullView'
                           : 'feedbackBoxSmallView'
                   }
                    `}
                    >
                        {feedbackIsSent ? (
                            <div
                                className={
                                    'atl-flex atl-justify-center atl-items-center'
                                }
                                style={{
                                    width: size?.width,
                                    height: size?.height,
                                }}
                            >
                                <UserFeedbackConfirmation />
                            </div>
                        ) : (
                            <div
                                ref={ref}
                                className={'atl-flex atl-flex-col atl-gap-24'}
                            >
                                <UserFeedbackInput
                                    handleKeyDown={handleKeyDown}
                                    setInput={setInput}
                                    letterCounter={letterCounter}
                                    contactRequest={setContactRequest}
                                    contactAnswer={contactRequest}
                                />
                                <UserFeedbackSendCancel
                                    isSendButtonClickable={
                                        isSendButtonClickable
                                    }
                                    sendFeedback={sendFeedback}
                                    resetFeedbackBox={resetFeedbackBox}
                                    setIsFeedbackBoxOpen={setIsFeedbackBoxOpen}
                                    input={input}
                                />
                                {showNotSupportInfo && (
                                    <UserFeedbackQuestionField />
                                )}
                            </div>
                        )}
                    </div>
                </Draggable>
            </FloatingSurface>
        </FloatingSurfaceBackdrop>
    );
}
