import { CustomerCategoryEnum } from '@General/types/customerCategory.enum';

export const isZtl = window.segmentationData?.modules?.moduleRemitZtl ?? false;
export const isAutoPay =
    window.segmentationData?.modules?.moduleRemitAutoPay ?? false;
export const isZtlOrAutoPay = isZtl || isAutoPay;

export const hasAttestation =
    window.segmentationData?.modules?.moduleApproveVoucher &&
    window.segmentationData?.modules?.moduleaccountingexternal;

export const companyStartDateYear =
    window.segmentationData?.companyStartDateYear;

export const loginEmployeeId = window.loginEmployeeId;

export const isProduction = window.productionMode ?? false;

export const tripletexCustomerCategoryId2: CustomerCategoryEnum =
    window.segmentationData?.tripletexCustomerCategoryId2 || 0;

export const isAuthDirectRemitZtl: boolean =
    window.segmentationData?.roles?.isAuthDirectRemitZtl ?? false;

export const isAccountant = window.segmentationData?.isAccountant;

export const isActiveAccountantProxy = window.isActiveAccountantProxy;

export const language = window.locale === 'en_GB' ? 'en' : 'no';

export const isEaccountingMigrated = window.isEaccountingMigrated;

export const isNewReconciliationPilot =
    window.segmentationData?.pilotFeatures?.BankReconciliationAtlas;

export const isReconciliationOptInKillSwitchOn =
    window.killSwitches?.NewReconciliationOptIn;
