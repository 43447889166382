import {
    AvailableColumns,
    DefaultColumns,
    SortingType,
} from '@Page/Goldshark/Utils/constant';
import { Cell, CellLabel, Column, NumberCell, TextCell } from '@tlx/atlas';
import {
    CountCell,
    NameCellAdvanced,
    NumberCellAdvanced,
    StockOfGoodsCell,
} from '@Page/Goldshark/Components/TableComponents';
import React from 'react';
import { ProductAPIModel } from '@Page/Goldshark/API/interface';
import { FormattedMessage } from 'react-intl';
export const tableSortBy: { [key: string]: (sortDirection: string) => string } =
    {
        nameColumn: (sortDirection) =>
            sortDirection === 'ascending'
                ? SortingType.NAME_ASCENDING
                : SortingType.NAME_DESCENDING,
        numberColumn: (sortDirection) =>
            sortDirection === 'ascending'
                ? SortingType.NUMBER_ASCENDING
                : SortingType.NUMBER_DESCENDING,
    };

export const tableColumns: {
    [key: string]: {
        name: string;
        default: string[];
        available: string[];
    };
} = {
    stocktakingProductTable: {
        name: `stocktaking-selector-${window.loginEmployeeId}`,
        default: ['numberSortable', 'nameSortable'],
        available: ['numberSortable', 'nameSortable'],
    },
    resaleProductTable: {
        name: `resale-product-selector-${window.loginEmployeeId}`,
        default: DefaultColumns.RESALE_PRODUCT,
        available: AvailableColumns.RESALE_PRODUCT,
    },
    supplierConnectionTable: {
        name: `supplier-connection-selector-${window.loginEmployeeId}`,
        default: DefaultColumns.SUPPLIER_CONNECTION,
        available: AvailableColumns.SUPPLIER_CONNECTION,
    },
    supplierConnectionTableDetailed: {
        name: `supplier-connection-selector-detailed-${window.loginEmployeeId}`,
        default: DefaultColumns.SUPPLIER_CONNECTION_DETAILED,
        available: AvailableColumns.SUPPLIER_CONNECTION_DETAILED,
    },
    supplierConnectionTableExternal: {
        name: `supplier-connection-selector-external-${window.loginEmployeeId}`,
        default: DefaultColumns.SUPPLIER_CONNECTION_EXTERNAL,
        available: AvailableColumns.SUPPLIER_CONNECTION_EXTERNAL,
    },
};
interface CellProps {
    row: ProductAPIModel;
    count?: string;
    handleOnChangeCount?: (value: string) => void;
}
export const rowCellRender: {
    [key: string]: (props: CellProps) => JSX.Element;
} = {
    cost: ({ row }) => (
        <Cell align={'right'}>
            <CellLabel>{getMessage('text_price_purchase')}</CellLabel>
            <NumberCell>{row.cost ?? 0}</NumberCell>
        </Cell>
    ),
    costExternal: ({ row }) => (
        <Cell align={'right'}>
            <CellLabel>{getMessage('text_price_purchase')}</CellLabel>
            <NumberCell>{row.costExcludingVatCurrency ?? 0}</NumberCell>
        </Cell>
    ),
    count: ({ count, handleOnChangeCount }) => (
        <CountCell value={count ?? ''} onChange={handleOnChangeCount} />
    ),
    nameSortable: ({ row }) => (
        <TextCell className="atl-flex-col atl-justify-center atl-items-start">
            {row.name && row.name !== '' ? row.name : '-'}
        </TextCell>
    ),
    nameSortableDetailed: ({ row }) => (
        <NameCellAdvanced
            name={row.name}
            resaleName={row.resaleProduct?.name}
        />
    ),
    numberSortable: ({ row }) => (
        <TextCell className="atl-flex-col atl-justify-center atl-items-start">
            {row.number !== '' ? row.number : '-'}
        </TextCell>
    ),
    numberSortableDetailed: ({ row }) => (
        <NumberCellAdvanced
            number={row.number}
            resaleNumber={row.resaleProduct?.number}
        />
    ),
    numberSortableExternal: ({ row }) => {
        const wholesalerNumber = (row.elNumber ?? '') + (row.nrfNumber ?? '');
        return (
            <TextCell
                className={'atl-flex-col atl-justify-center atl-items-start'}
            >
                {wholesalerNumber !== '' ? wholesalerNumber : '-'}
            </TextCell>
        );
    },
    stockOfGoods: ({ row }) => (
        <StockOfGoodsCell
            stockOfGoods={row.resaleProduct?.stockOfGoods ?? ''}
            isStockItem={row.resaleProduct?.isStockItem}
        />
    ),
};
export const headerCellRender: {
    [key: string]: JSX.Element;
} = {
    cost: (
        <Column
            id="column-cost"
            align={'right'}
            width={'0.6fr'}
            className={'atl-text-right'}
        >
            <FormattedMessage id="text_price_purchase" />
        </Column>
    ),
    costExternal: (
        <Column
            id="column-cost"
            align={'right'}
            width={'0.6fr'}
            className={'atl-text-right'}
        >
            <FormattedMessage id="text_price_purchase" />
        </Column>
    ),
    count: (
        <Column id="column-count" align={'right'} width={'0.55fr'}>
            <FormattedMessage id="text_count" />
        </Column>
    ),
    nameSortable: (
        <Column sortable id="nameColumn" width={'1.5fr'}>
            <FormattedMessage id="text_name" />
        </Column>
    ),
    nameSortableDetailed: (
        <Column sortable id="nameColumn" width={'1.5fr'}>
            <FormattedMessage id="text_name" />
        </Column>
    ),
    numberSortable: (
        <Column sortable id="numberColumn" width={'0.7fr'}>
            <FormattedMessage id="text_number" />
        </Column>
    ),
    numberSortableDetailed: (
        <Column sortable id="numberColumn" width={'0.7fr'}>
            <FormattedMessage id="text_number" />
        </Column>
    ),
    numberSortableExternal: (
        <Column sortable id="numberColumn" width={'0.7fr'}>
            <FormattedMessage id="text_number" />
        </Column>
    ),
    stockOfGoods: (
        <Column id="column-stockOfGoods" align={'right'} width={'0.5fr'}>
            <FormattedMessage id="text_stock_of_goods" />
        </Column>
    ),
};
